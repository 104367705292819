<template>
  <div class="flex-column messageMainView">
    <pageHead class="messageMainView_head" title="消息" hide-back>
      <!--      <p slot="right" class="allReadBtn" @click="handleAllRead">全部已读</p>-->
    </pageHead>
    <Tabs
      @changeTabs="handleChangeTabs"
      @allRead="handleAllRead"
    ></Tabs>
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadList"
      class="message-main-content">
      <div v-if="!isLoaded">
        <van-skeleton title :row="10" />
      </div>
      <div v-show="tabsActive === 0 && isLoaded" class="messageMainView_typeList">
        <typeIcon
          v-for="item in typeIconOptions"
          v-bind="item"
          :key="item.routeName"
          @click="handleTypeIconClick(item)"
        />
      </div>
      <div v-show="tabsActive === 1 && isLoaded" class="messageMainView_messageList">
        <messageItem
          v-for="(item, index) in records"
          :key="item.id + '_' + index"
          :friendItem="item"
          :username="item.friend.userName"
          :icon-url="item.friend.userAvatar"
          :srcs="item.friend.userAvatar"
          :admin="item.friend.admin"
          :message="getMessageContent(item)"
          :has-tip="item.noReadCount !== 0"
          @click="handleChat(item)"
          :timeStamp="getDateTime(item.createTime)"
        />
        <no-more
          v-if="!queryParams.hasNextPage && false"
          text="暂无更多消息"
          class="no-more"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "./components/tabs";
import pageHead from "@/components/pageHead";
import NoMore from "@/components/uitls/NoMore";
import typeIcon from "./components/typeIcon";
import messageItem from "./components/messageItem";
import {privateletterList, privateletterAllRead} from "@/api/privateletter";
import {getAttCount} from "@/api/message";
import {setItem} from "@/utils/storage";
// import { isURL } from "@/utils/utils";
// import { USER_IMAGE } from "@/config/config";

export default {
  components: {
    Tabs,
    pageHead,
    NoMore,
    typeIcon,
    messageItem,
  },
  data () {
    const initImgUrl = (name) => {
      return `/images/messageMainView/${name}`;
    };
    return {
      isLoaded: false,
      tabsActive: this.$store.state.returnMessages,
      records: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      typeIconOptions: [
        {
          label: "提到我的",
          routeName: "messageAboutMeView",
          iconUrl: initImgUrl("aboutMe.png"),
          count: 0,
        },
        {
          label: "点赞我的",
          routeName: "messageLikeView",
          iconUrl: initImgUrl("like.png"),
          count: 0,
        },
        {
          label: "评论/回复我的",
          routeName: "messageCommentView",
          iconUrl: initImgUrl("comments.png"),
          count: 0,
        },
        {
          label: "系统消息",
          routeName: "systemAdviseView",
          iconUrl: initImgUrl("system.png"),
          count: 0,
        },
        {
          label: "成员加入申请",
          routeName: "applyJoinView",
          iconUrl: initImgUrl("members.png"),
          count: -1,
        },
      ],
      counts: {},
    };
  },
  created () {
    // 加载更多
    // this.loadList();
    this.getAttCount();
  },
  methods: {
    // 切换tabs
    handleChangeTabs (index) {
      this.isLoaded = false
      this.tabsActive = index;
      this.queryParams.pageNum = 1
      this.queryParams.hasNextPage = true
      if (index === 1) {
        this.loadList();
      } else {
        this.getAttCount()
      }
    },
    // 获取消息数量
    async getAttCount () {
      try {
        const res = await getAttCount();
        const {
          applyRecordMessageCount,
          atCount,
          commentCount,
          sysMessageCount,
          thumbsUpCount,
        } = res;
        this.typeIconOptions[0].count = atCount;
        this.typeIconOptions[1].count = thumbsUpCount;
        this.typeIconOptions[2].count = commentCount;
        this.typeIconOptions[3].count = sysMessageCount;
        this.typeIconOptions[4].count = applyRecordMessageCount;
      } finally {
        this.isLoaded = true
      }
    },
    // 后端日期转换成时间戳
    getDateTime (res) {
      if (res.replaceAll) {
        return new Date(res.replaceAll("-", "/")).getTime();
      } else {
        // 解决安卓'replaceAll is not a function'
        return new Date(res).getTime();
      }
    },
    /**
     * 处理全部已读
     */
    async handleAllRead () {
      await privateletterAllRead();

      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      };

      // this.getAttCount()
      // this.typeIconOptions.forEach(item => item.count = 0)
      this.loadList();
    },
    /**
     * 分类图标点击事件
     * @param routeName
     */
    handleTypeIconClick ({routeName} = {}) {
      // 回复我的页面，默认进入未回复的
      if (routeName === 'messageAboutMeView') {
        setItem('aboutMeActive', 0)
      }
      this.$router.push({name: routeName});
    },
    /**
     * 聊天
     */
    handleChat (data) {
      this.$router.push({name: "messageChatView", query: {...data.friend}});
    },

    /**
     * 获取朋友用户名
     */
    getFriendInfo (item) {
      let userId = this.$store.getters.user.user_id;
      // console.log(this.$store.getters.user.user_id, item.user1Id);
      let obj = {};
      if (parseInt(item.user1Id) === parseInt(userId)) {
        obj.userId = item.user2Id;
        obj.userName = item.user2Name;
        obj.userAvatar = item.user2Avatar;
        obj.avatar = item.user2Avatar;
        obj.admin = item.superAdmin2;
        obj.userAvatarFrame = item.user2AvatarFrameUrl
        obj.avatarFrameUrl = item.user2AvatarFrameUrl
      } else {
        obj.userId = item.user1Id;
        obj.userName = item.user1Name;
        obj.userAvatar = item.user1Avatar;
        obj.avatar = item.user1Avatar;
        obj.admin = item.superAdmin1;
        obj.userAvatarFrame = item.user1AvatarFrameUrl
        obj.avatarFrameUrl = item.user1AvatarFrameUrl
      }
      return obj;
    },
    // 加载数据
    loadList () {
      if (this.queryParams.hasNextPage) {
        privateletterList(this.queryParams).then((res) => {
          this.queryParams.pageNum++
          this.queryParams.hasNextPage = res.current <= res.pages;
          let newRecords = res.records;
          let newR = newRecords.map((item) => {
            item.friend = this.getFriendInfo(item);
            return item;
          });
          // console.log(newR);
          if (this.queryParams.pageNum === 2) {
            this.records = newR
          } else {
            this.records = this.records.concat(newR);
          }
        }).finally(() => {
          this.isLoaded = true
        })
      }
    },
    // 根据msgType判断显示消息内容或图片
    getMessageContent (data) {
      if (data.msgType == 2) {
        return "[图片]";
      } else {
        return data.content;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messageMainView {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  height: 100%;

  &_head {
    border-bottom: solid 1px $backgroundGray;
  }

  .allReadBtn {
    @include remark;
  }

  &_typeList {
    margin-top: 5px;
    padding: 5px 20px 20px;
    background-color: white;
  }

  &_messageList {
    flex: 1;
    overflow-y: auto;
    margin-top: 5px;
  }

  &_noMessage {
    font-size: 14px;
    color: $mainTextColor;
    text-align: center;
  }

  .message-main-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
