<template>
  <div class="flex-center-row tabs-home">

    <div class="item-tab" @click="changeTabs(0)">
      <van-badge  v-if="messageFlag.remindFlag" aria-disabled="true" dot color="#fe5800">
        <div class="child" />
        <h3 :class="{ tabsActiveText: 0 === tabsActiveIndex }">
          提醒
        </h3>
        <img
          v-if="tabsActiveIndex === 0"
          class="bottom-border"
          src="/images/appMainView/tabsBottom.png"
          alt=""
        />
      </van-badge>
      <div class="flex-column" v-else>
        <div class="child" />
        <h3 :class="{ tabsActiveText: 0 === tabsActiveIndex }">
          提醒
        </h3>
        <img
          v-if="tabsActiveIndex === 0"
          class="bottom-border"
          src="/images/appMainView/tabsBottom.png"
          alt=""
        />
      </div>
    </div>
    <div class="item-tab" @click="changeTabs(1)">
      <van-badge v-if="messageFlag.privateLetterFlag" aria-disabled="true" dot color="#fe5800">
        <div class="child" />
        <h3 :class="{ tabsActiveText: 1 === tabsActiveIndex }">
          私信
        </h3>
        <img
          v-if="tabsActiveIndex === 1"
          class="bottom-border"
          src="/images/appMainView/tabsBottom.png"
          alt=""
        />
      </van-badge>
      <div class="flex-column" v-else>
        <div class="child" />
        <h3 :class="{ tabsActiveText: 1 === tabsActiveIndex }">
          私信
        </h3>
        <img
          v-if="tabsActiveIndex === 1"
          class="bottom-border"
          src="/images/appMainView/tabsBottom.png"
          alt=""
        />
      </div>
    </div>

    <div class="new-hot">
      <!-- <span
        v-show="tabsActiveIndex == 0"
        :class="allReadRemind == 0 ? 'all-read' : ''"
        @click="allRead"
        >全部已读</span
      > -->
      <span
        v-show="tabsActiveIndex === 1"
        :class="messageFlag.privateLetterFlag ? 'all-read' : ''"
        @click="allRead"
      >全部已读</span
      >
    </div>
  </div>
</template>

<script>
import {newMsgFlag} from "@/api/message";

export default {
  name: "index",
  data() {
    return {
      tabs: [
        {
          title: "提醒",
          id: 1,
        },
        {
          title: "私信",
          id: 2,
        },
      ],
      messageFlag: {}, // 是否显示提醒和消息小圆点
      tabsActiveIndex: this.$store.state.returnMessages,
    };
  },
  created () {
    this.getMsgFlag()
  },
  methods: {
    changeTabs(index) {
      this.tabsActiveIndex = index;
      this.$store.state.returnMessages = index;
      this.$emit("changeTabs", index);
    },
    allRead() {
      if(this.messageFlag.privateLetterFlag){
        this.getMsgFlag()
        this.$emit("allRead");
      }
    },
    // 获取底部消息小圆点
    getMsgFlag () {
      newMsgFlag().then(res => {
        this.messageFlag = res || {}
        // if (res.remindFlag || res.privateLetterFlag) {
        //   this.newMsg = true
        // }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-home {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 11px 15px 10px 15px;


  .item-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 28px;

    ::v-deep .van-badge__wrapper{
      display: flex;
      flex-direction: column;
    }

    h3 {
      width: max-content;
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: $moreColor;
      line-height: 22px;
    }

    .tabsActiveText {
      color: #333333;
      font-weight: bold;
    }

    .bottom-border {
      width: 23px;
      height: 5px;
      margin-top: 4px;
    }
  }


  .new-hot {
    position: absolute;
    top: 11px;
    right: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    //width: 55px;

    > span {
      width: max-content;
      font-size: 12px;
      text-align: RIGHT;
      color: $remarkColor;
      line-height: 20px;
      letter-spacing: 0;
      padding: 0 2px 0 6px;
    }

    .hot-change {
      width: 10px;
      height: 10px;
      line-height: 20px;
      //margin-left: 4px;
      padding: 2px 0 2px 4px;
    }
  }

  .all-read {
    color: #7597ee !important;
  }

  .remind {
    left: 160px;
    top: 6px;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fe5800;
  }

  .direct-messages {
    right: 120px;
    top: 6px;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fe5800;
  }
}
</style>
