<template>
  <div v-show="count !== -1" class="typeIcon" @click="handleClick">
    <img :src="iconUrl" class="typeIcon_img">
    <div class="info">
      <p class="info_label">{{ label }}</p>
      <p class="count" v-if="count > 0">+{{count}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: String,
    label: String,
    count: Number
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.typeIcon {
  position: relative;
  display: flex;
  align-items: center;

  &_img {
    width: 44px;
    border-radius: 50%;
  }

  .info {
    flex: 1;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #F4F4F4;
    padding: 15px 0 15px 20px;

    &_label {
      @include mainText;
      text-align: center;
    }
    .count {
      font-size: 14px;
      color: $mainColor;
    }
  }
}
</style>
