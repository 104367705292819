<template>
  <div class="messageItem" @click="handleClick">
    <div
      class="messageItem_userIcon"
      :class="hasTip && 'messageItem_userIconHasTip'"
    >
      <AvatarFrame
        class="header_img"
        :user-info="friendItem.friend"></AvatarFrame>
    </div>
    <div class="flex-row-space-between-hCenter messageItem_content">
      <div class="messageItem_content_left">
        <p class="username">{{ username }}</p>
        <p class="message" v-html="message">{{ message }}</p>
      </div>
      <div>
        <p class="time">{{ timeStampFormat }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {ADMIN_IMAGE} from "@/config/config";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
// import src from "local_node_modules/alioss/src";

export default {
  components: {
    AvatarFrame
  },
  props: {
    iconUrl: String,
    hasTip: Boolean,
    username: String,
    message: String,
    timeStamp: Number,
    admin: Number,
    srcs: String,
    friendItem: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    timeStampFormat () {
      const dayNow = dayjs().locale("zh-cn").format("YYYY-MM-DD");
      const dayMsg = dayjs(this.timeStamp).locale("zh-cn").format("YYYY-MM-DD");
      if (dayNow === dayMsg) {
        return dayjs(this.timeStamp).locale("zh-cn").format("A HH:mm");
      } else {
        return dayjs(this.timeStamp).locale("zh-cn").format("MM-DD");
      }
    },
    avatar () {
      if (this.admin === 1 || this.admin === 2) {
        return ADMIN_IMAGE;
      } else {
        return this.iconUrl;
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit("click");
      this.$store.state.returnMessages = 1;
      console.log(this.$store.state.returnMessages);
    },
  },
};
</script>

<style lang="scss" scoped>
.messageItem {
  display: flex;
  background-color: white;
  height: 70px;
  padding-left: 19px;
  align-items: center;

  &:not(:last-child) {
    .messageItem_content {
      border-bottom: solid 1px $backgroundGray;
    }
  }

  &_userIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;

    header_img{
      width: 50px;
      height: 50px;
    }

    > div {
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_userIconHasTip:after {
    content: "";
    right: 3px;
    top: 3px;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $mainColor;
  }

  &_content {
    flex: 1;
    margin-right: 21px;
    height: 100%;

    &_left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .username {
      @include subtitle;
      margin: 0;
    }

    .message {
      @include remark;
      @include ellipsis(1);
      margin: 0;
      margin-top: 5px;
      max-width: 210px;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
    }

    .time {
      @include remark;
    }
  }
}
</style>
